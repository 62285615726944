export type ColorFormat = 'rgb' | 'rgba' | 'hsl' | 'hsla' | 'color';
export interface ColorObject {
  type: ColorFormat;
  values: [number, number, number] | [number, number, number, number];
  colorSpace?: 'srgb' | 'display-p3' | 'a98-rgb' | 'prophoto-rgb' | 'rec-2020';
}

/**
 * Returns a number whose value is limited to the given range.
 * @param {number} value The value to be clamped
 * @param {number} min The lower boundary of the output range
 * @param {number} max The upper boundary of the output range
 * @returns {number} A number in the range [min, max]
 */
function clamp(value: number, min = 0, max = 1) {
  if (process.env.NODE_ENV !== 'production') {
    if (value < min || value > max) {
      console.error(`MUI: The value provided ${value} is out of range [${min}, ${max}].`);
    }
  }

  return Math.min(Math.max(min, value), max);
}

/**
 * Converts a color from CSS hex format to CSS rgb format.
 * @param {string} color - Hex color, i.e. #nnn or #nnnnnn
 * @returns {string} A CSS rgb color string
 */
export function hexToRgb(color: string): string {
  color = color.slice(1);

  const re = new RegExp(`.{1,${color.length >= 6 ? 2 : 1}}`, 'g');
  let colors = color.match(re) as string[];

  if (colors && colors[0].length === 1) {
    colors = colors.map((n) => n + n);
  }

  return colors
    ? `rgb${colors.length === 4 ? 'a' : ''}(${colors
        .map((n, index) => {
          return index < 3 ? parseInt(n, 16) : Math.round((parseInt(n, 16) / 255) * 1000) / 1000;
        })
        .join(', ')})`
    : '';
}

function intToHex(int: number) {
  const hex = int.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
}

/**
 * Returns an object with the type and values of a color.
 *
 * Note: Does not support rgb % values.
 * @param {string} color - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla(), color()
 * @returns {object} - A color object: {type: string, values: number[]}
 */
export function decomposeColor(color: string): ColorObject {
  if (color.charAt(0) === '#') {
    return decomposeColor(hexToRgb(color));
  }

  const marker = color.indexOf('(');
  const type = color.substring(0, marker) as ColorFormat;

  if (['rgb', 'rgba', 'hsl', 'hsla', 'color'].indexOf(type) === -1) {
    throw new Error(
      `Unsupported ${color} color.
        The following formats are supported: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla(), color().
        `
    );
  }

  let values: string | string[] | number[] = color.substring(marker + 1, color.length - 1);
  let colorSpace;

  if (type === 'color') {
    values = values.split(' ');
    colorSpace = values.shift() || '';

    if (values.length === 4 && values[3].charAt(0) === '/') {
      values[3] = values[3].slice(1);
    }

    if (['srgb', 'display-p3', 'a98-rgb', 'prophoto-rgb', 'rec-2020'].indexOf(colorSpace) === -1) {
      throw new Error(
        `Unsupported ${colorSpace} color space.
            The following color spaces are supported: srgb, display-p3, a98-rgb, prophoto-rgb, rec-2020.
            `
      );
    }
  } else {
    values = values.split(',');
  }

  values = values.map((value) => parseFloat(value));

  return {
    type,
    values: values as [number, number, number] | [number, number, number, number],
    colorSpace: colorSpace as 'srgb' | 'display-p3' | 'a98-rgb' | 'prophoto-rgb' | 'rec-2020',
  };
}

/**
 * Returns a channel created from the input color.
 *
 * @param {string} color - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla(), color()
 * @returns {string} - The channel for the color, that can be used in rgba or hsla colors
 */
export const colorChannel = (color: string): string => {
  const decomposedColor = decomposeColor(color);

  return decomposedColor.values
    .slice(0, 3)
    .map((val, idx) => (decomposedColor.type.indexOf('hsl') !== -1 && idx !== 0 ? `${val}%` : val))
    .join(' ');
};
export const private_safeColorChannel = (color: string, warning?: string) => {
  try {
    return colorChannel(color);
  } catch (error) {
    if (warning && process.env.NODE_ENV !== 'production') {
      console.warn(warning);
    }
    return color;
  }
};

/**
 * Converts a color object with type and values to a string.
 * @param {object} color - Decomposed color
 * @param {string} color.type - One of: 'rgb', 'rgba', 'hsl', 'hsla', 'color'
 * @param {array} color.values - [n,n,n] or [n,n,n,n]
 * @returns {string} A CSS color string
 */
export function recomposeColor(color: ColorObject): string {
  const { type, colorSpace } = color;
  const { values } = color;
  let _values: (number | string)[] | string = [...values];

  if (type.indexOf('rgb') !== -1) {
    // Only convert the first 3 values to int (i.e. not alpha)
    _values = _values.map((n, i) => (i < 3 ? parseInt(`${n}`, 10) : n));
  } else if (type.indexOf('hsl') !== -1) {
    _values[1] = `${_values[1]}%`;
    _values[2] = `${_values[2]}%`;
  }

  if (type.indexOf('color') !== -1) {
    _values = `${colorSpace} ${_values.join(' ')}`;
  } else {
    _values = `${_values.join(', ')}`;
  }

  return `${type}(${_values})`;
}

/**
 * Converts a color from CSS rgb format to CSS hex format.
 * @param {string} color - RGB color, i.e. rgb(n, n, n)
 * @returns {string} A CSS rgb color string, i.e. #nnnnnn
 */
export function rgbToHex(color: string): string {
  // Idempotent
  if (color.indexOf('#') === 0) {
    return color;
  }

  const { values } = decomposeColor(color);
  return `#${values.map((n, i) => intToHex(i === 3 ? Math.round(255 * n) : n)).join('')}`;
}

/**
 * Converts a color from hsl format to rgb format.
 * @param {string} color - HSL color values
 * @returns {string} rgb color values
 */
export function hslToRgb(color: string): string {
  const _color = decomposeColor(color);
  const { values } = _color;
  const h = values[0];
  const s = values[1] / 100;
  const l = values[2] / 100;
  const a = s * Math.min(l, 1 - l);
  const f = (n: number, k = (n + h / 30) % 12) => l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);

  let type = 'rgb';
  const rgb = [Math.round(f(0) * 255), Math.round(f(8) * 255), Math.round(f(4) * 255)];

  if (_color.type === 'hsla') {
    type += 'a';
    rgb.push(values[3]!);
  }

  return recomposeColor({
    type: type as ColorFormat,
    values: rgb as [number, number, number] | [number, number, number, number],
  });
}
/**
 * The relative brightness of any point in a color space,
 * normalized to 0 for darkest black and 1 for lightest white.
 *
 * Formula: https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-tests
 * @param {string} color - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla(), color()
 * @returns {number} The relative brightness of the color in the range 0 - 1
 */
export function getLuminance(color: string): number {
  const _color = decomposeColor(color);

  let rgb =
    _color.type === 'hsl' || _color.type === 'hsla'
      ? decomposeColor(hslToRgb(color)).values
      : _color.values;

  rgb = rgb.map((val) => {
    if (_color.type !== 'color') {
      val /= 255; // normalized
    }

    return val <= 0.03928 ? val / 12.92 : ((val + 0.055) / 1.055) ** 2.4;
  }) as [number, number, number] | [number, number, number, number];

  // Truncate at 3 digits
  return Number((0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2]).toFixed(3));
}

/**
 * Calculates the contrast ratio between two colors.
 *
 * Formula: https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-tests
 * @param {string} foreground - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla()
 * @param {string} background - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla()
 * @returns {number} A contrast ratio value in the range 0 - 21.
 */
export function getContrastRatio(foreground: string, background: string): number {
  const lumA = getLuminance(foreground);
  const lumB = getLuminance(background);
  return (Math.max(lumA, lumB) + 0.05) / (Math.min(lumA, lumB) + 0.05);
}

/**
 * Sets the absolute transparency of a color.
 * Any existing alpha values are overwritten.
 * @param {string} color - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla(), color()
 * @param {number} value - value to set the alpha channel to in the range 0 - 1
 * @returns {string} A CSS color string. Hex input values are returned as rgb
 */
export function alpha(color: string, value: number): string {
  const _color = decomposeColor(color);

  value = clamp(value);

  if (_color.type === 'rgb' || _color.type === 'hsl') {
    _color.type += 'a';
  }

  if (_color.type === 'color') {
    _color.values[3] = `/${value}` as unknown as number;
  } else {
    _color.values[3] = value;
  }

  return recomposeColor(_color);
}
export function private_safeAlpha(color: string, value: number, warning?: string): string {
  try {
    return alpha(color, value);
  } catch (error) {
    if (warning && process.env.NODE_ENV !== 'production') {
      console.warn(warning);
    }
    return color;
  }
}

/**
 * Darkens a color.
 * @param {string} color - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla(), color()
 * @param {number} coefficient - multiplier in the range 0 - 1
 * @returns {string} A CSS color string. Hex input values are returned as rgb
 */
export function darken(color: string, coefficient: number): string {
  const _color = decomposeColor(color);

  coefficient = clamp(coefficient);

  if (_color.type.indexOf('hsl') !== -1) {
    _color.values[2] *= 1 - coefficient;
  } else if (_color.type.indexOf('rgb') !== -1 || _color.type.indexOf('color') !== -1) {
    for (let i = 0; i < 3; i += 1) {
      _color.values[i] *= 1 - coefficient;
    }
  }

  return recomposeColor(_color);
}
export function private_safeDarken(color: string, coefficient: number, warning?: string) {
  try {
    return darken(color, coefficient);
  } catch (error) {
    if (warning && process.env.NODE_ENV !== 'production') {
      console.warn(warning);
    }
    return color;
  }
}

/**
 * Lightens a color.
 * @param {string} color - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla(), color()
 * @param {number} coefficient - multiplier in the range 0 - 1
 * @returns {string} A CSS color string. Hex input values are returned as rgb
 */
export function lighten(color: string, coefficient: number): string {
  const _color = decomposeColor(color);

  coefficient = clamp(coefficient);

  if (_color.type.indexOf('hsl') !== -1) {
    _color.values[2] += (100 - _color.values[2]) * coefficient;
  } else if (_color.type.indexOf('rgb') !== -1) {
    for (let i = 0; i < 3; i += 1) {
      _color.values[i] += (255 - _color.values[i]) * coefficient;
    }
  } else if (_color.type.indexOf('color') !== -1) {
    for (let i = 0; i < 3; i += 1) {
      _color.values[i] += (1 - _color.values[i]) * coefficient;
    }
  }

  return recomposeColor(_color);
}
export function private_safeLighten(color: string, coefficient: number, warning?: string): string {
  try {
    return lighten(color, coefficient);
  } catch (error) {
    if (warning && process.env.NODE_ENV !== 'production') {
      console.warn(warning);
    }
    return color;
  }
}

/**
 * Darken or lighten a color, depending on its luminance.
 * Light colors are darkened, dark colors are lightened.
 * @param {string} color - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla(), color()
 * @param {number} coefficient=0.15 - multiplier in the range 0 - 1
 * @returns {string} A CSS color string. Hex input values are returned as rgb
 */
export function emphasize(color: string, coefficient: number = 0.15): string {
  return getLuminance(color) > 0.5 ? darken(color, coefficient) : lighten(color, coefficient);
}
export function private_safeEmphasize(
  color: string,
  coefficient?: number,
  warning?: string
): string {
  try {
    return private_safeEmphasize(color, coefficient);
  } catch (error) {
    if (warning && process.env.NODE_ENV !== 'production') {
      console.warn(warning);
    }
    return color;
  }
}

/**
 * Generates a consistent hex color for a given random string.
 * @param input - The input string to generate a color for.
 * @param type - The type of color to generate.
 * @param palette - The color palette to use.
 * @returns A hex color string (e.g., #1a2b3c).
 */

type ColorType = 'default' | 'pastel' | 'light' | 'dark' | 'vibrant';
type ColorPalette = 'all' | 'blue' | 'green' | 'warm' | 'cool' | 'purple' | 'turquoise';

const colorCache = new Map<string, string>();
let hueShift = 0; // Global hue shift to spread colors evenly

export function randomStringToHexColor(
  input: string,
  type: ColorType = 'default',
  palette: ColorPalette = 'all'
): string {
  if (colorCache.has(input)) return colorCache.get(input)!;

  let hash = 0;
  for (let i = 0; i < input.length; i++) {
    hash = input.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Define hue ranges for different palettes
  const palettes: Record<string, [number, number]> = {
    all: [0, 360],
    blue: [190, 250],
    green: [100, 160],
    warm: [0, 60],
    cool: [180, 320],
    purple: [260, 310],
    turquoise: [160, 190],
  };

  const [minHue, maxHue] = palettes[palette];
  let hue = ((Math.abs(hash) % (maxHue - minHue)) + minHue + hueShift) % 360;

  hueShift += 37; // Increase hue shift to avoid similar colors

  let saturation = 80;
  let lightness = 50;

  switch (type) {
    case 'pastel':
      saturation = 75;
      lightness = 70;

      break;

    case 'light':
      saturation = 60;
      lightness = 85;

      break;

    case 'dark':
      saturation = 80;
      lightness = 35;

      break;

    case 'vibrant':
      saturation = 100;
      lightness = 50;

      break;
  }

  const color = hslToHex(hue, saturation, lightness);

  colorCache.set(input, color); // Cache the color

  return color;
}

// Convert HSL to HEX
function hslToHex(h: number, s: number, l: number): string {
  s /= 100;
  l /= 100;

  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = l - c / 2;

  let r = 0,
    g = 0,
    b = 0;

  if (h >= 0 && h < 60) [r, g, b] = [c, x, 0];
  else if (h >= 60 && h < 120) [r, g, b] = [x, c, 0];
  else if (h >= 120 && h < 180) [r, g, b] = [0, c, x];
  else if (h >= 180 && h < 240) [r, g, b] = [0, x, c];
  else if (h >= 240 && h < 300) [r, g, b] = [x, 0, c];
  else if (h >= 300 && h < 360) [r, g, b] = [c, 0, x];

  const toHex = (n: number) =>
    Math.round((n + m) * 255)
      .toString(16)
      .padStart(2, '0');

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}
