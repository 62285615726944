// 3rd
import { z } from 'zod';

// App - Types
import { castItemCategoryDtoToItemCategory } from '@/types/unions/dtos';
import type { ItemCategoryDto } from '@/types/unions/dtos';
import { castKnowledgeItemLabelConfigurationDtoToKeyOfKnowledgeItemLabels } from '@/types/knowledge-item/dtos';
import type { KnowledgeItemLabelConfigurationDto } from '@/types/knowledge-item/dtos';
import type { ApplicationStatHighlight, HighlightType } from '../application-stat-highlight';

export const HighlightTypesDto = ['Label', 'NewDocument', 'UNKNOWN'] as const;

export type HighlightTypeDto = (typeof HighlightTypesDto)[number];

const castHighlightTypeDtoToHighlightType = (dto: HighlightTypeDto): HighlightType => {
  if (dto === 'Label') return 'label';

  if (dto === 'NewDocument') return 'new_document_under_classification';

  return 'UNKNOWN';
};

export const ZodApplicationHighlightStatDto = z.object({
  count: z.number(),
  name: z.string(),
  highlightType: z.enum(HighlightTypesDto),
});

export type ApplicationHighlightStatDto = z.infer<typeof ZodApplicationHighlightStatDto>;

export const castApplicationHighlightStatDtoToApplicationHighlightStat = (
  dto: ApplicationHighlightStatDto
): ApplicationStatHighlight => {
  const type = castHighlightTypeDtoToHighlightType(dto.highlightType);
  let value = dto.name;

  if (type === 'label') {
    const labelKey = castKnowledgeItemLabelConfigurationDtoToKeyOfKnowledgeItemLabels(
      dto.name as KnowledgeItemLabelConfigurationDto
    );

    if (dto.name !== 'Api' && labelKey !== 'api') {
      value = labelKey;
    }
  }

  if (type === 'new_document_under_classification') {
    const categoryKey = castItemCategoryDtoToItemCategory(dto.name as ItemCategoryDto);

    if (categoryKey !== 'UNKNOWN') {
      value = categoryKey;
    }
  }

  return {
    count: dto.count,
    value,
    type,
  };
};
