// App - Types
import type { ApplicationQuestionnaireGuidelinesStructure } from '../application-questionnaire-guidelines';

export const ApplicationQuestionnaireGuidelinesStructuresDto = [
  'DataFlowDiagram',
  'CloudArchitecture',
  'SystemArchitectureDiagram',
  'UNKNOWN',
] as const;

export type ApplicationQuestionnaireGuidelinesStructureDto =
  (typeof ApplicationQuestionnaireGuidelinesStructuresDto)[number];

export const castApplicationQuestionnaireGuidelinesStructureDtoToApplicationQuestionnaireGuidelinesStructure =
  (
    dto: ApplicationQuestionnaireGuidelinesStructureDto
  ): ApplicationQuestionnaireGuidelinesStructure => {
    if (dto === 'DataFlowDiagram') return 'data_flow_diagram';

    if (dto === 'CloudArchitecture') return 'cloud_architecture';

    if (dto === 'SystemArchitectureDiagram') return 'system_architecture_diagram';

    return 'UNKNOWN';
  };

export const castApplicationQuestionnaireGuidelinesStructureToApplicationQuestionnaireGuidelinesStructureDto =
  (
    structure: ApplicationQuestionnaireGuidelinesStructure
  ): ApplicationQuestionnaireGuidelinesStructureDto => {
    if (structure === 'data_flow_diagram') return 'DataFlowDiagram';

    if (structure === 'cloud_architecture') return 'CloudArchitecture';

    if (structure === 'system_architecture_diagram') return 'SystemArchitectureDiagram';

    return 'UNKNOWN';
  };
