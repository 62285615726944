// App - Types
import type { ApplicationRiskProfile } from '../application-risk-profile';

export const ApplicationRiskProfilesDto = ['High', 'Medium', 'Low', 'UNKNOWN'] as const;

export type ApplicationRiskProfileDto = (typeof ApplicationRiskProfilesDto)[number];

export const castApplicationRiskProfileDtoToApplicationRiskProfile = (
  dto: ApplicationRiskProfileDto
): ApplicationRiskProfile => {
  if (dto === 'High') return 'high';

  if (dto === 'Medium') return 'medium';

  if (dto === 'Low') return 'low';

  return 'UNKNOWN';
};

export const castApplicationRiskProfileToApplicationRiskProfileDto = (
  riskProfile: ApplicationRiskProfile
): ApplicationRiskProfileDto => {
  if (riskProfile === 'high') return 'High';

  if (riskProfile === 'medium') return 'Medium';

  if (riskProfile === 'low') return 'Low';

  return 'UNKNOWN';
};
