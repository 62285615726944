// React & Next
import { Fragment } from 'react';

// 3rd
import JSXMarkdown from 'markdown-to-jsx';
import type { MarkdownToJSX } from 'markdown-to-jsx';
import { Code, Divider, Flex, ListItem, OrderedList, UnorderedList } from '@chakra-ui/react';
import type { FlexProps } from '@chakra-ui/react';

// App - Types
import type { TextProps } from '@/components/atoms/typography';
import type { TooltipProps } from '@/components/molecules/tooltips';

// App - Other
import { OutlineInfoIcon } from '@/components/atoms/icon';
import { Text } from '@/components/atoms/typography';
import { Tag } from '@/components/molecules/tag';
import { Tooltip } from '@/components/molecules/tooltips';

export type MarkdownProps = FlexProps & {
  wrapper?: 'wrap' | 'nowrap';
  overrides?: MarkdownToJSX.Overrides;
  children: string;
};

export const Markdown = ({
  wrapper = 'wrap',
  overrides = {},
  children,
  ...flexProps
}: MarkdownProps) => {
  return (
    <JSXMarkdown
      options={{
        wrapper: ({ children }) =>
          wrapper === 'wrap' ? (
            <Flex {...flexProps}>{children}</Flex>
          ) : (
            <Fragment>{children}</Fragment>
          ),
        forceWrapper: true,
        forceBlock: true,
        overrides: { ...componentsOverrides, ...overrides },
      }}
    >
      {children}
    </JSXMarkdown>
  );
};

const componentsOverrides = {
  h1: {
    component: Text,
    props: {
      className: 'h1',
      variant: 'detail-semibold',
      color: 'text.high-tone',
    },
  },
  h2: {
    component: Text,
    props: {
      className: 'h2',
      variant: 'detail-semibold',
      color: 'text.high-tone',
    },
  },
  h3: {
    component: Text,
    props: {
      className: 'h3',
      variant: 'detail-semibold',
      color: 'text.high-tone',
    },
  },
  h4: {
    component: Text,
    props: {
      className: 'h4',
      variant: 'detail-semibold',
      color: 'text.high-tone',
    },
  },
  h5: {
    component: Text,
    props: {
      className: 'h5',
      variant: 'detail-semibold',
      color: 'text.high-tone',
    },
  },
  h6: {
    component: Text,
    props: {
      className: 'h6',
      variant: 'detail-semibold',
      color: 'text.high-tone',
    },
  },
  strong: {
    component: Text,
    props: {
      display: 'inline-block',
      className: 'strong',
      variant: 'detail-semibold',
      color: 'text.high-tone',
    },
  },
  p: {
    component: Text,
    props: {
      className: 'p',
      variant: 'detail',
      color: 'text.high-tone',

      // _notFirst: {
      //   '&:has(.strong)': {
      //     mt: 'md',
      //   },
      // },
    },
  },
  span: {
    component: Text,
    props: {
      className: 'span',
      variant: 'detail',
      color: 'text.high-tone',
    },
  },
  br: {
    component: Divider,
    props: {
      border: 'none',
      my: 'xs',
    },
  },
  code: {
    component: Code,
    props: {
      sx: {
        fontSize: '12px',
        color: 'text.secondary',
        fontWeight: 'normal',
        borderRadius: '4px',
        py: '0px',
        px: '4px',
      },
    },
  },
  ol: {
    component: OrderedList,
    props: {
      className: 'ordered-list',
      textStyle: 'detail',
      px: '8px',
      color: 'text.high-tone',
    },
  },
  ul: {
    component: UnorderedList,
    props: {
      className: 'unordered-list',
      textStyle: 'detail',
      px: 'xs',
      mt: 'xs',
      color: 'text.high-tone',
    },
  },
  li: {
    component: ListItem,
    props: {
      className: 'list-item',
      textStyle: 'detail',
      color: 'text.high-tone',
    },
  },
  Flex: {
    component: Flex,
  },
  Text: {
    component: ({ children, ...props }: TextProps) => <Text {...props}>{children}</Text>,
    props: {
      className: 'Text',
      color: 'text.high-tone',
    },
  },
  UnorderedList: {
    component: UnorderedList,
    props: {
      className: 'unordered-list',
      textStyle: 'detail',
      px: 'xs',
      mt: 'xs',
      color: 'text.high-tone',
    },
  },
  ListItem: {
    component: ListItem,
    props: {
      className: 'list-item',
      textStyle: 'detail',
      color: 'text.high-tone',
    },
  },
  Code: {
    component: Code,
    props: {
      sx: {
        fontSize: '12px',
        color: 'text.secondary',
        fontWeight: 'normal',
        borderRadius: '4px',
        py: '0px',
        px: '4px',
      },
    },
  },
  Tag: {
    component: Tag,
  },
  Tooltip: {
    component: ({ children, ...props }: TooltipProps) => (
      <Tooltip {...props}>
        <span>{children}</span>
      </Tooltip>
    ),
  },
  OutlineInfoIcon: {
    component: OutlineInfoIcon,
  },
};
