// App - Types
import type { RequirementCountermeasureStatus } from '../requirement-countermeasure-status';

export const RequirementCountermeasureStatusesDto = [
  'Approved',
  'Suggested',
  'UnderReview',
  'UNKNOWN',
] as const;

export type RequirementCountermeasureStatusDto =
  (typeof RequirementCountermeasureStatusesDto)[number];

export const castRequirementCountermeasureStatusDtoToRequirementCountermeasureStatus = (
  dto: RequirementCountermeasureStatusDto
): RequirementCountermeasureStatus => {
  if (dto === 'Approved') return 'approved';

  if (dto === 'Suggested') return 'suggested';

  if (dto === 'UnderReview') return 'under_review';

  return 'UNKNOWN';
};

export const castRequirementCountermeasureStatusToRequirementCountermeasureStatusDto = (
  status: RequirementCountermeasureStatus
): RequirementCountermeasureStatusDto => {
  if (status === 'approved') return 'Approved';

  if (status === 'suggested') return 'Suggested';

  if (status === 'under_review') return 'UnderReview';

  return 'UNKNOWN';
};
