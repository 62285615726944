export const EntityUrlReferenceResolutionStatuses = [
  'not_resolved_general',
  'missing_entity',
  'missing_integration',
  'resolved',
  'UNKNOWN',
] as const;

export type EntityUrlReferenceResolutionStatus =
  (typeof EntityUrlReferenceResolutionStatuses)[number];

export const EntityUrlReferenceTypes = [
  'document',
  'ticket',
  'pull_request',
  'image',
  'diagram',
  'meeting',
  'actions',
  'home_page',
  'api',
  'board',
  'tag',
  'branch',
  'user',
  'audio',
  'video',
  'issue',
  'release_note',
  'discussion',
  'dashboard',
  'alert',
  'whiteboard',
  'compare',
  'repository',
  'commit',
  'sheet',
  'other',
  'UNKNOWN',
] as const;

export type EntityUrlReferenceType = (typeof EntityUrlReferenceTypes)[number];

export const EntityUrlReferenceSources = [
  'confluence',
  'jira',
  'github',
  'bitbucket',
  'azure_devops',
  'google_drive',
  'trello',
  'notion',
  'miro',
  'power_point',
  'excel',
  'word',
  'drawio',
  'figma',
  'lucidchart',
  'linear',
  'gitlab',
  'looker',
  'kibana',
  'grafana',
  'tableau',
  'canva',
  'smartsheet',
  'basecamp',
  'asana',
  'fibery',
  'airtable',
  'postman',
  'service_now',
  'share_point',
  'one_drive',
  'dropbox',
  'slack',
  'mattermost',
  'aws',
  'microsoft_azure',
  'google_cloud',
  'docker',
  'kubernetes',
  'pager_duty',
  'quip',
  'whimsical',
  'zoom',
  'teams',
  'service_titan',
  'monday',
  'salesforce',
  'zendesk',
  'other',
  'UNKNOWN',
] as const;

export type EntityUrlReferenceSource = (typeof EntityUrlReferenceSources)[number];

export type EntityUrlReference = {
  source: EntityUrlReferenceSource;
  rank: number;
  additionalType: string;
  type: EntityUrlReferenceType;
  resolutionStatus: EntityUrlReferenceResolutionStatus;
  url: string;
};

export const isEntityUrlReferenceResolved = (
  status: EntityUrlReferenceResolutionStatus
): boolean => {
  return status === 'resolved';
};
