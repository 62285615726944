// App - Types
import type { RequirementCodeImplementationStatus } from '../requirement-code-implementation-status';

export const RequirementCodeImplementationStatusesDto = [
  'Implemented',
  'Missing',
  'Irrelevant',
  'PotentiallyImplemented',
  'UNKNOWN',
] as const;

export type RequirementRelatedPullRequestImplementationStatusDto =
  (typeof RequirementCodeImplementationStatusesDto)[number];

export const castRequirementCodeImplementationStatusDtoToRequirementCodeImplementationStatus = (
  dto: RequirementRelatedPullRequestImplementationStatusDto
): RequirementCodeImplementationStatus => {
  if (dto === 'Implemented') return 'implemented';

  if (dto === 'Missing') return 'missing';

  if (dto === 'Irrelevant') return 'irrelevant';

  if (dto === 'PotentiallyImplemented') return 'potentially_implemented';

  return 'UNKNOWN';
};

export const castRequirementRelatedPullRequestImplementationStatusToRequirementRelatedPullRequestImplementationStatusDto =
  (
    implementationStatus: RequirementCodeImplementationStatus
  ): RequirementRelatedPullRequestImplementationStatusDto => {
    if (implementationStatus === 'implemented') return 'Implemented';

    if (implementationStatus === 'missing') return 'Missing';

    if (implementationStatus === 'irrelevant') return 'Irrelevant';

    if (implementationStatus === 'potentially_implemented') return 'PotentiallyImplemented';

    return 'UNKNOWN';
  };
