// App - Types
import type { KnowledgeSource } from '../integration/knowledge-source';
import type { EntityType } from './entity-type';

export type EntityId = {
  source: KnowledgeSource;
  type: EntityType;
  collectionId: string;
  itemId: string;
};

const ID_SEPARATOR = '~';

export const stringifyEntityId = (id: EntityId): string =>
  `${id.source}${ID_SEPARATOR}${id.type}${ID_SEPARATOR}${id.collectionId}${ID_SEPARATOR}${id.itemId}`;

export const parseEntityIdStr = (id: string): EntityId => {
  const split = id.split(ID_SEPARATOR);

  if (split.length !== 4) {
    return {
      source: 'UNKNOWN',
      type: 'UNKNOWN',
      collectionId: '',
      itemId: '',
    };
  }

  const [source, type, collectionId, itemId] = id.split(ID_SEPARATOR);

  return {
    source: source as KnowledgeSource,
    type: type as EntityType,
    collectionId,
    itemId,
  };
};

export const compareEntityIds = (a: EntityId, b: EntityId): boolean =>
  stringifyEntityId(a) === stringifyEntityId(b);

export const isValidEntityId = (id: EntityId): boolean =>
  !!id.source && !!id.type && !!id.collectionId && !!id.itemId;
