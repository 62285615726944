// App - Types
import type { ApplicationProgrammingLanguage } from '../application-programming-language';

export const ApplicationProgrammingLanguagesDto = [
  'Angular',
  'C',
  'Cpp',
  'CSharp',
  'Css',
  'Dart',
  'DockerFile',
  'Dockerfile',
  'Go',
  'Groovy',
  'Html',
  'Ini',
  'Java',
  'JavaScript',
  'JavaScriptSalesforceLightningComponents',
  'JavaScriptServiceNowGlideApi',
  'Json',
  'Kotlin',
  'Lua',
  'Makefile',
  'Markdown',
  'Matlab',
  'ObjectiveC',
  'Perl',
  'Php',
  'Python',
  'PowerShell',
  'R',
  'React',
  'Ruby',
  'Rust',
  'Scala',
  'Shell',
  'Sql',
  'Swift',
  'Terraform',
  'TypeScript',
  'Toml',
  'VisualBasic',
  'Vue',
  'Yaml',
  'Unknown',
  'UNKNOWN',
] as const;

export type ApplicationProgrammingLanguageDto = (typeof ApplicationProgrammingLanguagesDto)[number];

export const castApplicationProgrammingLanguageDtoToApplicationProgrammingLanguage = (
  dto: ApplicationProgrammingLanguageDto
): ApplicationProgrammingLanguage => {
  if (dto === 'Angular') return 'angular';

  if (dto === 'C') return 'c';

  if (dto === 'Cpp') return 'cpp';

  if (dto === 'CSharp') return 'csharp';

  if (dto === 'Css') return 'css';

  if (dto === 'Dart') return 'dart';

  if (dto === 'DockerFile' || dto === 'Dockerfile') return 'dockerfile';

  if (dto === 'Go') return 'go';

  if (dto === 'Groovy') return 'groovy';

  if (dto === 'Html') return 'html';

  if (dto === 'Ini') return 'ini';

  if (dto === 'Java') return 'java';

  if (dto === 'JavaScript') return 'javascript';

  if (dto === 'JavaScriptSalesforceLightningComponents')
    return 'javascript_salesforce_lightning_components';

  if (dto === 'JavaScriptServiceNowGlideApi') return 'javascript_service_now_glide_api';

  if (dto === 'Json') return 'json';

  if (dto === 'Kotlin') return 'kotlin';

  if (dto === 'Lua') return 'lua';

  if (dto === 'Makefile') return 'makefile';

  if (dto === 'Markdown') return 'markdown';

  if (dto === 'Matlab') return 'matlab';

  if (dto === 'ObjectiveC') return 'objective_c';

  if (dto === 'Perl') return 'perl';

  if (dto === 'Php') return 'php';

  if (dto === 'Python') return 'python';

  if (dto === 'PowerShell') return 'powershell';

  if (dto === 'R') return 'r';

  if (dto === 'React') return 'react';

  if (dto === 'Ruby') return 'ruby';

  if (dto === 'Rust') return 'rust';

  if (dto === 'Scala') return 'scala';

  if (dto === 'Shell') return 'shell';

  if (dto === 'Sql') return 'sql';

  if (dto === 'Swift') return 'swift';

  if (dto === 'Terraform') return 'terraform';

  if (dto === 'TypeScript') return 'typescript';

  if (dto === 'Toml') return 'toml';

  if (dto === 'VisualBasic') return 'visual_basic';

  if (dto === 'Vue') return 'vue';

  if (dto === 'Yaml') return 'yaml';

  return 'UNKNOWN';
};

export const castApplicationProgrammingLanguageToApplicationProgrammingLanguageDto = (
  programmingLanguage: ApplicationProgrammingLanguage
): ApplicationProgrammingLanguageDto => {
  if (programmingLanguage === 'angular') return 'Angular';

  if (programmingLanguage === 'c') return 'C';

  if (programmingLanguage === 'cpp') return 'Cpp';

  if (programmingLanguage === 'csharp') return 'CSharp';

  if (programmingLanguage === 'css') return 'Css';

  if (programmingLanguage === 'dart') return 'Dart';

  if (programmingLanguage === 'dockerfile') return 'DockerFile';

  if (programmingLanguage === 'go') return 'Go';

  if (programmingLanguage === 'groovy') return 'Groovy';

  if (programmingLanguage === 'html') return 'Html';

  if (programmingLanguage === 'ini') return 'Ini';

  if (programmingLanguage === 'java') return 'Java';

  if (programmingLanguage === 'javascript') return 'JavaScript';

  if (programmingLanguage === 'javascript_salesforce_lightning_components')
    return 'JavaScriptSalesforceLightningComponents';

  if (programmingLanguage === 'javascript_service_now_glide_api')
    return 'JavaScriptServiceNowGlideApi';

  if (programmingLanguage === 'json') return 'Json';

  if (programmingLanguage === 'kotlin') return 'Kotlin';

  if (programmingLanguage === 'lua') return 'Lua';

  if (programmingLanguage === 'makefile') return 'Makefile';

  if (programmingLanguage === 'markdown') return 'Markdown';

  if (programmingLanguage === 'matlab') return 'Matlab';

  if (programmingLanguage === 'objective_c') return 'ObjectiveC';

  if (programmingLanguage === 'perl') return 'Perl';

  if (programmingLanguage === 'php') return 'Php';

  if (programmingLanguage === 'python') return 'Python';

  if (programmingLanguage === 'powershell') return 'PowerShell';

  if (programmingLanguage === 'r') return 'R';

  if (programmingLanguage === 'react') return 'React';

  if (programmingLanguage === 'ruby') return 'Ruby';

  if (programmingLanguage === 'rust') return 'Rust';

  if (programmingLanguage === 'scala') return 'Scala';

  if (programmingLanguage === 'shell') return 'Shell';

  if (programmingLanguage === 'sql') return 'Sql';

  if (programmingLanguage === 'swift') return 'Swift';

  if (programmingLanguage === 'terraform') return 'Terraform';

  if (programmingLanguage === 'typescript') return 'TypeScript';

  if (programmingLanguage === 'toml') return 'Toml';

  if (programmingLanguage === 'visual_basic') return 'VisualBasic';

  if (programmingLanguage === 'vue') return 'Vue';

  if (programmingLanguage === 'yaml') return 'Yaml';

  return 'UNKNOWN';
};
