// App - Types
import type { RequirementCountermeasureSource } from '../requirement-countermeasure-source';

export const RequirementCountermeasureSourcesDto = ['DetectedInCode', 'Manual', 'UNKNOWN'] as const;

export type RequirementCountermeasureSourceDto =
  (typeof RequirementCountermeasureSourcesDto)[number];

export const castRequirementCountermeasureSourceDtoToRequirementCountermeasureSource = (
  dto: RequirementCountermeasureSourceDto
): RequirementCountermeasureSource => {
  if (dto === 'DetectedInCode') return 'detected_in_code';

  if (dto === 'Manual') return 'manual';

  return 'UNKNOWN';
};

export const castRequirementCountermeasureSourceToRequirementCountermeasureSourceDto = (
  source: RequirementCountermeasureSource
): RequirementCountermeasureSourceDto => {
  if (source === 'detected_in_code') return 'DetectedInCode';

  if (source === 'manual') return 'Manual';

  return 'UNKNOWN';
};
