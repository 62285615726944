// App - Types
import type { EntityUrlReferenceType } from '@/types/entity';

// App - Other
import Locale from '@/locale/en.json';

const locale = Locale.components.translators.entity['entity-url-reference-type'];

type PluralizationOptions = {
  count: number;
};

export const translateEntityUrlReferenceType = (
  type: EntityUrlReferenceType | string,
  options?: PluralizationOptions
): string => {
  const useSingular = options?.count === 1;

  if (type === 'document') return useSingular ? locale['Document'] : locale['Documents'];

  if (type === 'ticket') return useSingular ? locale['Ticket'] : locale['Tickets'];

  if (type === 'pull_request') return useSingular ? locale['PR'] : locale['PRs'];

  if (type === 'image') return useSingular ? locale['Image'] : locale['Images'];

  if (type === 'diagram') return useSingular ? locale['Diagram'] : locale['Diagrams'];

  if (type === 'meeting') return useSingular ? locale['Meeting'] : locale['Meetings'];

  if (type === 'actions') return useSingular ? locale['Action'] : locale['Actions'];

  if (type === 'home_page') return useSingular ? locale['Home Page'] : locale['Home Pages'];

  if (type === 'api') return useSingular ? locale['API'] : locale['APIs'];

  if (type === 'board') return useSingular ? locale['Board'] : locale['Boards'];

  if (type === 'tag') return useSingular ? locale['Tag'] : locale['Tags'];

  if (type === 'branch') return useSingular ? locale['Branch'] : locale['Branches'];

  if (type === 'user') return useSingular ? locale['User'] : locale['Users'];

  if (type === 'audio') return useSingular ? locale['Audio Link'] : locale['Audio Links'];

  if (type === 'video') return useSingular ? locale['Video Link'] : locale['Video Links'];

  if (type === 'issue') return useSingular ? locale['Issue'] : locale['Issues'];

  if (type === 'release_note')
    return useSingular ? locale['Release Note'] : locale['Release Notes'];

  if (type === 'discussion') return useSingular ? locale['Discussion'] : locale['Discussions'];

  if (type === 'dashboard') return useSingular ? locale['Dashboard'] : locale['Dashboards'];

  if (type === 'alert') return useSingular ? locale['Alert'] : locale['Alerts'];

  if (type === 'whiteboard') return useSingular ? locale['Whiteboard'] : locale['Whiteboards'];

  if (type === 'compare') return useSingular ? locale['Compare'] : locale['Compares'];

  if (type === 'repository') return useSingular ? locale['Repository'] : locale['Repositories'];

  if (type === 'commit') return useSingular ? locale['Commit'] : locale['Commits'];

  if (type === 'sheet') return useSingular ? locale['Sheet'] : locale['Sheets'];

  if (type === 'other') return useSingular ? locale['Resource'] : locale['Resources'];

  return useSingular ? locale['Resource'] : locale['Resources'];
};
